import React from 'react';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import Layout from '@cjdev/visual-stack-redux/lib/layouts/ApplicationLayout';
import PageContent from '@cjdev/visual-stack/lib/components/PageContent';
import AxisZRotateCounterclockwiseIcon from 'mdi-react/AxisZRotateCounterclockwiseIcon';
import ServerSecurityIcon from 'mdi-react/ServerSecurityIcon'
import {
  PageHeader,
  PageTitle,
  PageDescription,
} from '@cjdev/visual-stack/lib/components/PageHeader';
import {
  Link,
  LinkContentWrapper,
  SideNav,
} from '@cjdev/visual-stack-redux/lib/components/SideNav';
import { reducer as visualStackReducer } from '@cjdev/visual-stack-redux';
import '@cjdev/visual-stack/lib/global';
import { CoordinatedReleases } from './Release';

const reducer = combineReducers({
  visualStack: visualStackReducer,
});

const store = createStore(reducer);

class App extends React.Component {
  render() {
    const sideNav = (
      <SideNav
        logoBackground="#00AF66"
        logo={<AxisZRotateCounterclockwiseIcon style={{ "fill": "#fff" }} />}
        appName="CJ Engineering 360°"
      >
        <Link hoverText="Almost there">
          <a href="/">
            <LinkContentWrapper
              icon={<ServerSecurityIcon />}
              label="Security Outcomes"
            />
          </a>
        </Link>
      </SideNav>
    );

    return (
      <div>
        <Provider store={store}>
          <Layout sideNav={sideNav}>
            <PageHeader>
              <PageTitle>
                Security Outcomes
                <PageDescription>Product Releases</PageDescription>
              </PageTitle>
            </PageHeader>
            <PageContent>
              <CoordinatedReleases />
            </PageContent>
          </Layout>
        </Provider>
      </div>
    );
  }
}


export default App;
