import React from 'react';
import Card from '@cjdev/visual-stack/lib/components/Card';
import * as R from 'ramda';
import './Release.css';

const ReleaseName = ({ value = "<name>" }) => <h2 className="appReleaseName, appValue">{value}</h2>;
const ProjectedCompletion = ({ value = "2005-01-01" }) => <div className="appProjectedCompletion">Projected Completion: <span className="value">{value}</span></div>;

const TeamReleaseName = ({ value = "<name>" }) => (
  <span>{value}</span>
);
const TeamReleaseBacklog = ({ value = "UNASSIGNED" }) => (
  <span>({value})</span>
);
const TeamReleaseCompletion = ({ value = "2000-09-09Z" }) => (
  <span>{value}</span>
);

const TeamRelease = ({ value = ({}) }) => (
  <li>
    <TeamReleaseName value={value.name} />&nbsp;
        <TeamReleaseBacklog value={value.backlog} />&nbsp;
    - &nbsp;
        <TeamReleaseCompletion value={value.externalCompletion} />
  </li>
);


const TeamReleases = ({ items = [] }) => (
  <div className="appTeamReleases">
    <h3>Releases</h3>
    <div className="items">
      <ul>
        {R.map(r => <TeamRelease value={r} />, items)}
      </ul>
    </div>
  </div>
);

const CoordinatedRelease = ({ value }) => (
  <Card >
    <div className="appRelease">
      <ReleaseName value={value.name} />
      <ProjectedCompletion value={value.externalCompletion} />
      <hr />
      <TeamReleases items={value.releases} />
    </div>
  </Card>
);

const RELEASES_URL = 'https://360.api.cj.com/projections/1/coordinated-releases';

class CoordinatedReleases extends React.Component {

  render() {
    const { loaded, items } = this.state;
    if (loaded) {
      return <div className="appReleases">
        {R.map(rel => (<CoordinatedRelease value={rel} key = {rel.name} />), items)}
      </div>
    } else {
      return <div className="loading">Loading...</div>
    }
  }

  componentDidMount() {

    if (this.state.mode === "demo") {
      this.setState({loaded: true, items: sample});
    } else {
      fetch(RELEASES_URL)
        .then(response => response.json())
        .then(data => this.setState({ loaded: true, items: data.releases }));
    }
  }

  state = {
    loaded: false,
    mode: this.props.mode,
    items: [],
  };

}


export { CoordinatedReleases };

const sample = [
  {
    name: "Security Initiative A",
    internalCompletion: "2019-11-05Z",
    externalCompletion: "2020-02-24Z",
    releases: [
      {
        name: "Security Intitiative A",
        backlog: "Relationships",
        internalCompletion: "2019-11-05Z",
        externalCompletion: "2019-12-17Z",
      },
      {
        name: "Security Intitiative A",
        backlog: "Empire",
        internalCompletion: "2019-10-01Z",
        externalCompletion: "2020-02-24Z",
      },
    ],
  },
  {
    name: "System Patches - 2019 Q3",
    internalCompletion: "2019-08-03Z",
    externalCompletion: "2019-08-24Z",
    releases: [
      {
        name: "System Patches",
        backlog: "Relationships",
        internalCompletion: "2019-07-05Z",
        externalCompletion: "2019-07-10Z",
      },
      {
        name: "Migrate Data Centers",
        backlog: "Tracking",
        internalCompletion: "2019-08-17Z",
        externalCompletion: "2019-08-24Z",
      },
      {
        name: "System Patches",
        backlog: "Empire",
        internalCompletion: "2019-07-31Z",
        externalCompletion: "2019-08-09Z",
      },
    ],
  },
  {
    name: "RFP for Bank of America",
    internalCompletion: "2019-07-01Z",
    externalCompletion: "2019-07-12Z",
    releases: [
      {
        name: "July RFPs",
        internalCompletion: "2019-07-01Z",
        externalCompletion: "2019-07-12Z",
      },
    ],
  },
];

